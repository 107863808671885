@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
      font-family: Helvetica, Arial Black, Hiragino Kaku Gothic ProN, Meiryo, Roboto, sans-serif;
    }

    #root {
      height: 100%;
    }
}
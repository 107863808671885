#exampleContent {
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0px;
  position: absolute;
  overflow-y: auto;
  background-color: #fff;
}

.unvisibleExampleButton {
  position: absolute;
  top: 15px;
  right: 15px;
}

#term {
  width: 100%;
  display: flex;
  padding: 15px 15px 5px;
}
#term p span {
  font-size: 2.7rem;
  margin-right: 20px;
}
#term p {
  font-size: 1.4rem;
}

#examples {
  width: 100%;
  min-height: 60%;
  padding: 20px 15px 10px;
  font-size: 1.7rem;
}

#examples p {
  margin-bottom: 15px;
  text-indent: -1.7rem;
  padding-left: 1.7rem;
}

#examples p span {
  color: #1f9edb;
}

/*
#header {
}

#issue {
    background-color: rgb(207, 204, 153);
}

#words {
   background-color:#ffd;
}

#bottom {
    background-color: #eee;
}
*/

/* ダミーカラー　*/

/* レスポンシブ start */

#wrapper {
  min-width: 300px;
  min-height: 500px;
  overflow-y: auto;
  height: -webkit-fill-available;
  height: 100vh;
  padding: 0 7px;
  width: 100%;
  background-color: #fff;
}

#header {
  height: 40px;
  background-color: #fff;
}

#header h1 {
  height: 20px;
  font-size: 1.7rem;
}

#main {
  min-width: 300px;
  min-height: 500px;
  height: -webkit-fill-available;
  height: 100vh;
}

#game {
  min-height: 500px;
  height: -webkit-fill-available;
  height: 100vh;
}

#start,
#end,
#game {
  padding-top: 40px;
}

#bottom {
  height: 70px;
}

.wordDiv {
  font-size: 1.7rem;
  border-radius: 15px;
  width: 30px;
  height: 30px;
  padding: 6px;
}

@media (min-width: 400px) {
  .wordDiv {
    font-size: 1.9rem;
    border-radius: 20px;
    width: 40px;
    height: 40px;
    padding: 8px;
  }
}

@media (min-width: 768px) {
  #header {
    height: 50px;
  }
  #header h1 {
    font-size: 2rem;
    height: 30px;
  }
  #start,
  #end,
  #game {
    padding-top: 50px;
  }

  .wordDiv {
    font-size: 2.3rem;
    border-radius: 25px;
    width: 50px;
    height: 50px;
    padding: 10px;
  }
}

_:lang(x) + _:-webkit-full-screen-document,
#wrapper {
  height: -webkit-fill-available;
}

/* レスポンシブ end */

.snackBar {
  position: absolute;
  top: 50;
  left: 0;
  right: 0;
  margin: 10pt 10pt 0;
  padding: 1rem;
  border-radius: 1rem;
  background-color: rgb(233, 246, 248);
  color: rgb(57, 82, 110);
  width: atuo;

  font-size: 2rem;
  z-index: 10;
}

#main {
  background-color: #fff;
  padding: 0 10px;
  position: relative;
}

#header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 50;
}

#header .title {
  min-width: 300px;
  max-width: 500px;
  width: 100%;

  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

#header h1 {
  margin-left: 10px;
  font-weight: 900;
}

#start {
  min-height: 500px;
  height: -webkit-fill-available;
  height: 100vh;
  position: relative;
}

footer {
  width: auto;
  margin-left: 10px;
  margin-bottom: 7px;
}

footer.endscreen {
  position: fixed;
  bottom: 0px;
}

footer.startscreen {
  position: fixed;
  bottom: 70px;
}

#start h2 {
  text-align: center;
  font-size: 3rem;
}

#qa {
  height: 100px;
}

#question {
  height: 60px;
  position: relative;
  text-align: center;
  font-size: 2.2rem;
  color: rgb(61, 61, 68);
  padding: 10px 5px;
  margin: 0 15px;
  border-bottom: solid 1px #ddd;
}

#question .sentence {
  letter-spacing: 0.6rem;
}

#question .level {
  position: absolute;
  bottom: -17px;
  left: -10px;
  letter-spacing: 0.2rem;
  font-size: 1.3rem;
  background-color: rgb(179, 235, 235);
  padding: 1px 5px;
}
#question .level span {
  font-size: 0.9rem;
}

#answers {
  font-size: 2.3rem;
  color: rgb(59, 143, 228);
  text-align: center;
  width: 100%;
  height: 40px;
}

#notice {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  text-align: center;
  font-size: 2.2rem;
  background-color: #fff;
  color: #999;
}

#words {
  /*    70 + 40 + 100 + 50
    game:420 (qa:100, words:210, control: 50)
    */
  min-height: calc(500px - 270px);
  height: calc(100vh - 270px);

  position: relative;
  max-width: 500px;
  width: 100%;
}

_:lang(x) + _:-webkit-full-screen-document,
#words {
  min-height: -webkit-calc(500px - 335px);
  height: -webkit-calc(100vh - 335px);
}

.wordDiv {
  text-align: center;
  color: #fff;
  font-weight: 900;
  background-color: dodgerblue;
}

.display-none {
  display: none;
}

#controle {
  padding-top: 10px;
  padding-bottom: 5px;
  height: 50px;
  width: 100%;
}

#controle .inter {
  text-align: center;
  width: 100%;
}

#bottom {
  background-color: rgb(255, 255, 255);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 51;
}

/* AboutScreen */
ul.about {
  text-indent: -1rem;
  line-height: 2rem;
}
ul.about li {
  list-style: none;
  margin-left: 1rem;
}

ul.about li::before {
  content: '●';
  padding-right: 5px;
  color: rgb(59, 219, 219);
}

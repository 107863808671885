html,
body,
header,
main,
ul,
li,
div,
p {
  box-sizing: border-box;
}

html {
  height: -webkit-fill-available;
  margin: 0;
  font-size: 9px;
  background-color: rgb(247, 246, 241);
}

@media (min-width: 640px) {
  html {
    font-size: 11px;
  }
}

body {
  margin: 0 auto;
  min-width: 300px;
  max-width: 500px;
  min-height: 500px;
  height: -webkit-fill-available;
  height: 100vh;
}

.App {
  min-width: 300px;
  max-width: 500px;
  min-height: 500px;
  height: -webkit-fill-available;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
}
